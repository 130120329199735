import React from 'react'
import { Carousel } from 'react-icecream-2'

import PageBanner from 'components/PageBanner'
import { useMobile } from 'hooks/ua'
import { wxNode, wxLite } from 'constants/img'

import pcBanner0 from './images/pcBanner0.png'
import pcBanner1 from './images/pcBanner1.jpg'
import pcBanner2 from './images/pcBanner2.jpg'
import pcBanner3 from './images/pcBanner3.jpg'
import mobileBanner0 from './images/mobileBanner0.png'
import mobileBanner1 from './images/mobileBanner1.png'
import mobileBanner2 from './images/mobileBanner2.png'
import mobileBanner3 from './images/mobileBanner3.png'

export default function Banner() {
  const isMobile = useMobile()

  return (
    <Carousel autoplaySpeed={5000} autoplay >
      <PageBanner
        title="盒子业务重磅来袭"
        desc="单线路 5M 起，轻松上机赚收益"
        extra={<img src={wxLite} style={{ marginTop: '36px', width: '132px', height: '132px' }} />}
        pcImg={pcBanner0}
        mobileImg={mobileBanner0}
        href={null}
        dark
        pcHeight={400}
      />
      <PageBanner
        title="关注官方账号，了解最新动态"
        desc="扫码入群，1V1 答疑解惑"
        pcImg={pcBanner1}
        mobileImg={mobileBanner1}
        href={isMobile ? '/activity' : null}
        dark
        pcHeight={400}
        btns={[{ href: '/activity', target: '_blank', children: '点击查看' }]}
      />
      <PageBanner
        title={isMobile ? '共享闲置带宽资源' : '共享闲置带宽资源，获取现金收益'}
        desc="每月获取稳定收益，欢迎加入 NiuLink 超级节点"
        pcImg={pcBanner2}
        mobileImg={mobileBanner2}
        href={isMobile ? '/cooperations' : null}
        dark
        pcHeight={400}
        btns={[{ href: '/cooperations', children: '了解详情' }]}
      />
      <PageBanner
        title="新人上机，福利多多"
        desc="添加 NiuLink 官方社群，新人福利活动享不停"
        extra={<img src={wxNode} style={{ marginTop: '36px', width: '132px', height: '132px' }} />}
        pcImg={pcBanner3}
        mobileImg={mobileBanner3}
        href={null}
        dark
        pcHeight={400}
      />
    </Carousel>
  )
}
